import { default as accountu3LOBdXhO9Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/account.vue?macro=true";
import { default as activityaDaEn3VGCoMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/activity.vue?macro=true";
import { default as alpha_45testIrYnHFBeygMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/alpha-test.vue?macro=true";
import { default as convertE3fu0pRPrmMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/convert.vue?macro=true";
import { default as fee_45discountsykG5njpQLzMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/fee-discounts.vue?macro=true";
import { default as _91futures_932VCtZRJHzFMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/futures/[futures].vue?macro=true";
import { default as helixc7etcpBc0LMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/helix.vue?macro=true";
import { default as indexulsjPZTWi3Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/index.vue?macro=true";
import { default as leaderboardXP3HJ15x2FMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/leaderboard.vue?macro=true";
import { default as maintenanceSmAB4GXuDxMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/maintenance.vue?macro=true";
import { default as _91market_93jiR2DAGlK2Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/market/[market].vue?macro=true";
import { default as marketspoJgtmFGpuMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/markets.vue?macro=true";
import { default as _91spot_931g4EHIgEZTMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/spot/[spot].vue?macro=true";
import { default as trade_45and_45earnGD6SV0tdS6Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/trade-and-earn.vue?macro=true";
export default [
  {
    name: accountu3LOBdXhO9Meta?.name ?? "account",
    path: accountu3LOBdXhO9Meta?.path ?? "/account",
    meta: accountu3LOBdXhO9Meta || {},
    alias: accountu3LOBdXhO9Meta?.alias || [],
    redirect: accountu3LOBdXhO9Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/account.vue").then(m => m.default || m)
  },
  {
    name: activityaDaEn3VGCoMeta?.name ?? "activity",
    path: activityaDaEn3VGCoMeta?.path ?? "/activity",
    meta: activityaDaEn3VGCoMeta || {},
    alias: activityaDaEn3VGCoMeta?.alias || [],
    redirect: activityaDaEn3VGCoMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: alpha_45testIrYnHFBeygMeta?.name ?? "alpha-test",
    path: alpha_45testIrYnHFBeygMeta?.path ?? "/alpha-test",
    meta: alpha_45testIrYnHFBeygMeta || {},
    alias: alpha_45testIrYnHFBeygMeta?.alias || [],
    redirect: alpha_45testIrYnHFBeygMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/alpha-test.vue").then(m => m.default || m)
  },
  {
    name: convertE3fu0pRPrmMeta?.name ?? "convert",
    path: convertE3fu0pRPrmMeta?.path ?? "/convert",
    meta: convertE3fu0pRPrmMeta || {},
    alias: convertE3fu0pRPrmMeta?.alias || [],
    redirect: convertE3fu0pRPrmMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/convert.vue").then(m => m.default || m)
  },
  {
    name: fee_45discountsykG5njpQLzMeta?.name ?? "fee-discounts",
    path: fee_45discountsykG5njpQLzMeta?.path ?? "/fee-discounts",
    meta: fee_45discountsykG5njpQLzMeta || {},
    alias: fee_45discountsykG5njpQLzMeta?.alias || [],
    redirect: fee_45discountsykG5njpQLzMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/fee-discounts.vue").then(m => m.default || m)
  },
  {
    name: _91futures_932VCtZRJHzFMeta?.name ?? "futures-futures",
    path: _91futures_932VCtZRJHzFMeta?.path ?? "/futures/:futures",
    meta: _91futures_932VCtZRJHzFMeta || {},
    alias: _91futures_932VCtZRJHzFMeta?.alias || [],
    redirect: _91futures_932VCtZRJHzFMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: helixc7etcpBc0LMeta?.name ?? "helix",
    path: helixc7etcpBc0LMeta?.path ?? "/helix",
    meta: helixc7etcpBc0LMeta || {},
    alias: helixc7etcpBc0LMeta?.alias || [],
    redirect: helixc7etcpBc0LMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/helix.vue").then(m => m.default || m)
  },
  {
    name: indexulsjPZTWi3Meta?.name ?? "index",
    path: indexulsjPZTWi3Meta?.path ?? "/",
    meta: indexulsjPZTWi3Meta || {},
    alias: indexulsjPZTWi3Meta?.alias || [],
    redirect: indexulsjPZTWi3Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardXP3HJ15x2FMeta?.name ?? "leaderboard",
    path: leaderboardXP3HJ15x2FMeta?.path ?? "/leaderboard",
    meta: leaderboardXP3HJ15x2FMeta || {},
    alias: leaderboardXP3HJ15x2FMeta?.alias || [],
    redirect: leaderboardXP3HJ15x2FMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: maintenanceSmAB4GXuDxMeta?.name ?? "maintenance",
    path: maintenanceSmAB4GXuDxMeta?.path ?? "/maintenance",
    meta: maintenanceSmAB4GXuDxMeta || {},
    alias: maintenanceSmAB4GXuDxMeta?.alias || [],
    redirect: maintenanceSmAB4GXuDxMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91market_93jiR2DAGlK2Meta?.name ?? "market-market",
    path: _91market_93jiR2DAGlK2Meta?.path ?? "/market/:market",
    meta: _91market_93jiR2DAGlK2Meta || {},
    alias: _91market_93jiR2DAGlK2Meta?.alias || [],
    redirect: _91market_93jiR2DAGlK2Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/market/[market].vue").then(m => m.default || m)
  },
  {
    name: marketspoJgtmFGpuMeta?.name ?? "markets",
    path: marketspoJgtmFGpuMeta?.path ?? "/markets",
    meta: marketspoJgtmFGpuMeta || {},
    alias: marketspoJgtmFGpuMeta?.alias || [],
    redirect: marketspoJgtmFGpuMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/markets.vue").then(m => m.default || m)
  },
  {
    name: _91spot_931g4EHIgEZTMeta?.name ?? "spot-spot",
    path: _91spot_931g4EHIgEZTMeta?.path ?? "/spot/:spot",
    meta: _91spot_931g4EHIgEZTMeta || {},
    alias: _91spot_931g4EHIgEZTMeta?.alias || [],
    redirect: _91spot_931g4EHIgEZTMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/spot/[spot].vue").then(m => m.default || m)
  },
  {
    name: trade_45and_45earnGD6SV0tdS6Meta?.name ?? "trade-and-earn",
    path: trade_45and_45earnGD6SV0tdS6Meta?.path ?? "/trade-and-earn",
    meta: trade_45and_45earnGD6SV0tdS6Meta || {},
    alias: trade_45and_45earnGD6SV0tdS6Meta?.alias || [],
    redirect: trade_45and_45earnGD6SV0tdS6Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/trade-and-earn.vue").then(m => m.default || m)
  },
  {
    name: _91futures_932VCtZRJHzFMeta?.name ?? "futures",
    path: _91futures_932VCtZRJHzFMeta?.path ?? "/futures",
    meta: _91futures_932VCtZRJHzFMeta || {},
    alias: _91futures_932VCtZRJHzFMeta?.alias || [],
    redirect: _91futures_932VCtZRJHzFMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _91spot_931g4EHIgEZTMeta?.name ?? "spot",
    path: _91spot_931g4EHIgEZTMeta?.path ?? "/spot",
    meta: _91spot_931g4EHIgEZTMeta || {},
    alias: _91spot_931g4EHIgEZTMeta?.alias || [],
    redirect: _91spot_931g4EHIgEZTMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/spot/[spot].vue").then(m => m.default || m)
  },
  {
    name: _91futures_932VCtZRJHzFMeta?.name ?? "binary-options-binaryOption",
    path: _91futures_932VCtZRJHzFMeta?.path ?? "/binary-options/:binaryOption",
    meta: _91futures_932VCtZRJHzFMeta || {},
    alias: _91futures_932VCtZRJHzFMeta?.alias || [],
    redirect: _91futures_932VCtZRJHzFMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _91futures_932VCtZRJHzFMeta?.name ?? "derivative-derivative",
    path: _91futures_932VCtZRJHzFMeta?.path ?? "/derivative/:derivative",
    meta: _91futures_932VCtZRJHzFMeta || {},
    alias: _91futures_932VCtZRJHzFMeta?.alias || [],
    redirect: _91futures_932VCtZRJHzFMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _91futures_932VCtZRJHzFMeta?.name ?? "perpetual-perpetual",
    path: _91futures_932VCtZRJHzFMeta?.path ?? "/perpetual/:perpetual",
    meta: _91futures_932VCtZRJHzFMeta || {},
    alias: _91futures_932VCtZRJHzFMeta?.alias || [],
    redirect: _91futures_932VCtZRJHzFMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  }
]